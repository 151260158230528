<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Applications</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Forms"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-easylets-applicationforms' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Applications
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="forms"
        hide-default-footer
        disable-pagination
        class="mt-4 mb-5"
        no-data-text="No forms found"
      >
        <template v-slot:item.property_address="{ item }">
          <div v-if="item.property_id">
            {{ item.property.full_address }}
          </div>
          <div v-else>{{ item.address_text }}</div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="yellow lighten-4 yellow--text text--darken-3"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-model="restoreDialog.open" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Restore Application</v-card-title>
          <v-card-text>Are you sure you want to restore ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetRestore"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="restoreDialog.loading"
              @click="saveRestore"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      searchTerm: "",

      breadcrumbs: [
        {
          text: "Application Forms",
          disabled: true,
        },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        form: {},
      },
      tableHeaders: [
        { text: "Property", value: "property_address" },
        { text: "Group", value: "group.lead.full_name" },
        { text: "Name", value: "customer.full_name" },
        { text: "Status", value: "form_status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    forms() {
      let forms =
        this.$store.getters["easylets/applicationformsStore/archived"];

      if (this.searchTerm !== "") {
        forms = forms.filter((c) => {
          const propertyName = c.property
            ? c.property.full_address.toLowerCase()
            : c.address_text.toLowerCase();
          const lead =
            c.group !== null ? c.group.lead.full_name.toLowerCase() : "";
          const customer = c.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            propertyName.includes(searchTerm) ||
            customer.includes(searchTerm) ||
            lead.includes(searchTerm)
          );
        });
      }

      return forms;
    },
  },

  methods: {
    openRestore(form) {
      this.restoreDialog.form = form;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.form = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("easylets/applicationformsStore/restoreForm", {
          appId,
          formId: this.restoreDialog.form.application_id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
